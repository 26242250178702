import { cronosTokens } from '@pancakeswap/tokens'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
    /**
     * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
     * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
     * The absence of a PID means the masterchef contract calls are skipped for this farm.
     * Prices are then fetched for all farms (masterchef + priceHelperLps).
     * Before storing to redux, farms without a PID are filtered out.
     */
    {
      pid: null,
      lpSymbol: 'CRO-THUNDR LP',
      lpAddress: '0x4F9B37C5AbdA884495dAc84d5be62A19c5365396',
      token: cronosTokens.thundr,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: 'WARZ-CRO LP',
      lpAddress: '0xDdCBee0F1AD911D6644A7361473Dfaef2048165d',
      token: cronosTokens.warz,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: 'MORPHS-CRO LP',
      lpAddress: '0xe5D47472Bd4cd5282dB9A03C6c12661459676D4B',
      token: cronosTokens.morphs,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: 'VRSE-CRO LP',
      lpAddress: '0x104Ae21aC93c67ee232F3841cabD7D26529B9BB7',
      token: cronosTokens.vrse,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: 'BC-CRO LP',
      lpAddress: '0x58624FF4128440c9136Df9bcf8B9431e90340945',
      token: cronosTokens.bc,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: '$XTC-WCRO LP',
      lpAddress: '0x4339Ad632438661Db56B4dd5ad82279de743676f',
      token: cronosTokens.xtc,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: 'MAD-WCRO LP',
      lpAddress: '0xB8F5eE44B45C3B5AaC0185dAD326EAE4B4A0e9BD',
      token: cronosTokens.mad,
      quoteToken: cronosTokens.wcro,
    },
    {
      pid: null,
      lpSymbol: '$GAINZ-WCRO LP',
      lpAddress: '0x1c9548C165Fe816b438514Cc40899c91178E3651',
      token: cronosTokens.gainz,
      quoteToken: cronosTokens.wcro,
    },
  ].map((p) => ({ ...p, token: serializeToken(p.token), quoteToken: serializeToken(p.quoteToken) }))

export default priceHelperLps
