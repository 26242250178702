import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { PartnerFarmsState, SerializedPartnerFarm, } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { cronosTokens, polygonTokens } from '@pancakeswap/tokens'
import { getBalanceNumber } from 'utils/formatBalance'
import { cronosRpcProvider } from 'utils/providers'
import { getPartnerFarmsPriceHelperLpFiles } from 'config/constants/priceHelperLps/index'
import { getPartnerFarms } from 'config/constants/partnerFarms'
import { NftToken } from 'config/constants/types'
import fetchFarms from '../farms/fetchFarms'
import getFarmsPrices, { nativeStableLpMap } from '../farms/getFarmsPrices'

import {
  fetchPartnerFarmsBlockLimits,
  fetchPartnerFarmsFeeConf,
  fetchPartnerFarmsNftStakingLimits,
  fetchPartnerFarmsTokenStakingLimits,
  fetchPartnerFarmsTotalBoostedShare,
  fetchPartnerFarmsTotalStakedNft,
  fetchPartnerFarmsTotalStakedToken,
} from './fetchPartnerFarms'
import {
  fetchHoldingNfts,
  fetchHoldingTokens,
  fetchNftAllowances,
  fetchPendingRewards,
  fetchStakedNfts,
  fetchStakedTokens,
  fetchTokenAllowances,
} from './fetchPartnerFarmsUser'
import { getLPTokenPrice, getTokenPricesFromFarm } from './helpers'
import { resetUserState } from '../global/actions'


const initialState: PartnerFarmsState = {
  data: [],
  userDataLoaded: false
}

export const fetchPartnerFarmsPublicDataAsync =
  (currentBlockNumber: number, chainId: number) => async (dispatch, getState) => {
    try {
      const partnerFarmsConfig = await getPartnerFarms(chainId)
      const [
        blockLimits,
        totalBoostedShares,
        tokenTotalStakings,
        nftTotalStakings,
        tokenStakingLimits,
        nftStakingLimits,
        feeConfs,
        currentBlock
      ] = await Promise.all([
        fetchPartnerFarmsBlockLimits(chainId),
        fetchPartnerFarmsTotalBoostedShare(chainId),
        fetchPartnerFarmsTotalStakedToken(chainId),
        fetchPartnerFarmsTotalStakedNft(chainId),
        fetchPartnerFarmsTokenStakingLimits(chainId),
        fetchPartnerFarmsNftStakingLimits(chainId),
        fetchPartnerFarmsFeeConf(chainId),
        currentBlockNumber ? Promise.resolve(currentBlockNumber) : cronosRpcProvider.getBlockNumber(),
      ])

      const blockLimitsSousIdMap = fromPairs(blockLimits.map((entry) => [entry.sousId, entry]))
      const totalBoostedSharesSousIdMap = fromPairs(totalBoostedShares.map((entry) => [entry.sousId, entry]))
      const tokenTotalStakingsSousIdMap = fromPairs(tokenTotalStakings.map((entry) => [entry.sousId, entry]))
      const nftTotalStakingsSousIdMap = fromPairs(nftTotalStakings.map((entry) => [entry.sousId, entry]))
      const tokenStakingLimitsSousIdMap = fromPairs(tokenStakingLimits.map((entry) => [entry.sousId, entry]))
      const nftStakingLimitsSousIdMap = fromPairs(nftStakingLimits.map((entry) => [entry.sousId, entry]))
      const feeConfsSousIdMap = fromPairs(feeConfs.map((entry) => [entry.sousId, entry]))

      const priceHelperLpsConfig = getPartnerFarmsPriceHelperLpFiles(chainId)
      const activePriceHelperLpsConfig = priceHelperLpsConfig.filter((priceHelperLpConfig) => {
        return (
          partnerFarmsConfig
            .filter(
              (p) => p.earningToken.address.toLowerCase() === priceHelperLpConfig.token.address.toLowerCase(),
            )
            .filter((p) => {
              const poolBlockLimit = blockLimitsSousIdMap[p.sousId]
              if (poolBlockLimit) {
                return poolBlockLimit.endBlock > currentBlock
              }
              return false
            }).length > 0
        )
      })
      const poolsWithDifferentFarmToken =
        activePriceHelperLpsConfig.length > 0 ? await fetchFarms(priceHelperLpsConfig, chainId) : []

      const farmsData = getState().farms.data
      const stableLp = nativeStableLpMap[chainId]
      const bnbBusdFarm =
        activePriceHelperLpsConfig.length > 0
          ? farmsData.find((farm) => farm.token.symbol === stableLp.stable && farm.quoteToken.symbol === stableLp.wNative)
          : null
      const bnbBusdFarm1 = poolsWithDifferentFarmToken.length > 0
        ? poolsWithDifferentFarmToken.find((farm) => farm.token.symbol === stableLp.stable && farm.quoteToken.symbol === stableLp.wNative)
        : null
      const farmsWithPricesOfDifferentTokenPools = bnbBusdFarm
        ? await getFarmsPrices([bnbBusdFarm, ...poolsWithDifferentFarmToken], chainId)
        : bnbBusdFarm1
          ? await getFarmsPrices([bnbBusdFarm1, ...poolsWithDifferentFarmToken], chainId)
          : []

      // Hard-code for THUNDR-CRO & WARZ-CRO partner farm, we use pool module for the partner farms, so lp price should be used instead of token price
      const thundrCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.thundr.address)
      const thundrCroLPPrice = getLPTokenPrice(thundrCroHelperFarm)
      const morphsCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.morphs.address)
      const morphsCroLPPrice = getLPTokenPrice(morphsCroHelperFarm)
      const warzCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.warz.address)
      const warzCroLPPrice = getLPTokenPrice(warzCroHelperFarm)
      const vrseCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.vrse.address)
      const vrseCroLPPrice = getLPTokenPrice(vrseCroHelperFarm)
      const bcCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.bc.address)
      const bcCroLPPrice = getLPTokenPrice(vrseCroHelperFarm)
      const hitMaticHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === polygonTokens.wmatic.address && farm.token.address === polygonTokens.hit.address)
      const hitMaticLPPrice = getLPTokenPrice(hitMaticHelperFarm)

      const xtcCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.xtc.address)
      const xtcCroLPPrice = getLPTokenPrice(xtcCroHelperFarm)
      const madCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.mad.address)
      const madCroLPPrice = getLPTokenPrice(xtcCroHelperFarm)
      const gainzCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.gainz.address)
      const gainzCroLPPrice = getLPTokenPrice(gainzCroHelperFarm)

      const prices = {
        ...getTokenPricesFromFarm([...farmsData, ...farmsWithPricesOfDifferentTokenPools]),
        [cronosTokens.thundrcrolp.address.toLocaleLowerCase()]: thundrCroLPPrice.toNumber(),
        [cronosTokens.warzcrolp.address.toLocaleLowerCase()]: warzCroLPPrice.toNumber(),
        [cronosTokens.morphscrolp.address.toLocaleLowerCase()]: morphsCroLPPrice.toNumber(),
        [cronosTokens.vrsecrolp.address.toLocaleLowerCase()]: vrseCroLPPrice.toNumber(),
        [cronosTokens.bccrolp.address.toLocaleLowerCase()]: bcCroLPPrice.toNumber(),
        [cronosTokens.xtccrolp.address.toLocaleLowerCase()]: xtcCroLPPrice.toNumber(),
        [cronosTokens.madcrolp.address.toLocaleLowerCase()]: madCroLPPrice.toNumber(),
        [cronosTokens.gainzcrolp.address.toLocaleLowerCase()]: gainzCroLPPrice.toNumber(),
        [polygonTokens.hitmaticlp.address.toLocaleLowerCase()]: hitMaticLPPrice.toNumber(),
      }

      const liveData = partnerFarmsConfig.map((p) => {
        const blockLimit = blockLimitsSousIdMap[p.sousId]
        const totalBoostedShare = totalBoostedSharesSousIdMap[p.sousId]
        const tokenTotalStaking = tokenTotalStakingsSousIdMap[p.sousId]
        const nftTotalStaking = nftTotalStakingsSousIdMap[p.sousId]
        const tokenStakingLimit = tokenStakingLimitsSousIdMap[p.sousId]
        const nftStakingLimit = nftStakingLimitsSousIdMap[p.sousId]
        const feeConf = feeConfsSousIdMap[p.sousId]

        const isPoolEndBlockExceeded =
          currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
        const isPoolFinished = p.isFinished || isPoolEndBlockExceeded

        const stakingTokenAddress = p.stakingToken.address ? p.stakingToken.address.toLowerCase() : null
        const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

        const earningTokenAddress = p.earningToken.address ? p.earningToken.address.toLowerCase() : null
        const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

        const apr = !isPoolFinished
          ? getPoolApr(
            stakingTokenPrice,
            earningTokenPrice,
            getBalanceNumber(new BigNumber(totalBoostedShare.totalBoostedShare), p.stakingToken.decimals),
            parseFloat(p.tokenPerBlock),
          )
          : 0

        return {
          ...blockLimit,
          ...totalBoostedShare,
          ...tokenTotalStaking,
          ...nftTotalStaking,
          ...tokenStakingLimit,
          ...nftStakingLimit,
          ...feeConf,
          stakingTokenPrice,
          earningTokenPrice,
          apr,
          isFinished: isPoolFinished,
        }
      })

      dispatch(setPartnerFarmsPublicData(liveData))
    } catch (error) {
      console.error('[PartnerFarms Action] error when getting public data', error)
    }
  }

export const fetchInitialPartnerFarmsData = createAsyncThunk<SerializedPartnerFarm[], { chainId: number }>(
  'partnerFarms/fetchInitialPartnerFarmsData',
  async ({ chainId }) => {
    const partnerFarms = await getPartnerFarms(chainId)
    return partnerFarms
  }
)

interface PartnerFarmUserDataResponse {
  sousId: number
  tokenAllowance: SerializedBigNumber
  nftAllowed: boolean
  holdingTokens: SerializedBigNumber
  stakedTokens: SerializedBigNumber
  holdingNfts: NftToken[]
  stakedNfts: NftToken[]
  pendingReward: SerializedBigNumber
}

export const fetchPartnerFarmsUserDataAsync = createAsyncThunk<
  PartnerFarmUserDataResponse[],
  { account: string; chainId: number }
>('partnerFarms/fetchPartnerFarmsUserData',
  // @ts-ignore
  async ({ account, chainId }, { rejectWithValue }) => {
    try {
      const partnerFarms = await getPartnerFarms(chainId)
      const [
        tokenAllowances,
        nftAllowances,
        holdingTokens,
        stakedTokens,
        holdingNfts,
        stakedNfts,
        pendingRewards
      ] = await Promise.all([
        fetchTokenAllowances(account, [], chainId),
        fetchNftAllowances(account, [], chainId),
        fetchHoldingTokens(account, [], chainId),
        fetchStakedTokens(account, [], chainId),

        fetchHoldingNfts(account, [], chainId),
        fetchStakedNfts(account, [], chainId),
        fetchPendingRewards(account, [], chainId)
      ])

      const userData = partnerFarms.map((p) => ({
        sousId: p.sousId,
        tokenAllowance: tokenAllowances[p.sousId],
        nftAllowed: nftAllowances[p.sousId],
        holdingTokens: holdingTokens[p.sousId],
        stakedTokens: stakedTokens[p.sousId],
        holdingNfts: holdingNfts[p.sousId],
        stakedNfts: stakedNfts[p.sousId],
        pendingReward: pendingRewards[p.sousId],
      }))
      return userData
    } catch (e) {
      return rejectWithValue(e)
    }
  })

export const updateUserTokenAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserTokenAllowance', async ({ sousId, account, chainId }) => {
  const allowances = await fetchTokenAllowances(account, [sousId], chainId)
  return { sousId, field: 'tokenAllowance', value: allowances[sousId] }
})

export const updateUserNftAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserNftAllowance', async ({ sousId, account, chainId }) => {
  const allowances = await fetchNftAllowances(account, [sousId], chainId)
  return { sousId, field: 'nftAllowed', value: allowances[sousId] }
})

export const updateUserHoldingTokens = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserHoldingTokens', async ({ sousId, account, chainId }) => {
  const tokenBalances = await fetchHoldingTokens(account, [sousId], chainId)
  return { sousId, field: 'holdingTokens', value: tokenBalances[sousId] }
})

export const updateUserStakedTokens = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserStakedTokens', async ({ sousId, account, chainId }) => {
  const stakedBalances = await fetchStakedTokens(account, [sousId], chainId)
  return { sousId, field: 'stakedTokens', value: stakedBalances[sousId] }
})

export const updateUserHoldingNfts = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserHoldingNfts', async ({ sousId, account, chainId }) => {
  const stakedNfts = await fetchHoldingNfts(account, [sousId], chainId)
  return { sousId, field: 'holdingNfts', value: stakedNfts[sousId] }
})

export const updateUserStakedNfts = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserStakedNfts', async ({ sousId, account, chainId }) => {
  const stakedNfts = await fetchStakedNfts(account, [sousId], chainId)
  return { sousId, field: 'stakedNfts', value: stakedNfts[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('partnerFarms/updateUserPendingReward', async ({ sousId, account, chainId }) => {
  const pendingRewards = await fetchPendingRewards(account, [sousId], chainId)
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})

export const PartnerFarmsSlice = createSlice({
  name: 'PartnerFarms',
  initialState,
  reducers: {
    setPartnerFarmPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPartnerFarmUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((p) => {
        if (p.sousId === sousId) {
          return { ...p, userDataLoaded: true, userData: action.payload.data }
        }
        return p
      })
    },
    setPartnerFarmsPublicData: (state, action) => {
      const livePartnerFarmsData: SerializedPartnerFarm[] = action.payload
      const livePartnerFarmsSousIdMap = fromPairs(livePartnerFarmsData.map((entry) => [entry.sousId, entry]))
      state.data = state.data.map((p) => {
        const livePartnerFarmData = livePartnerFarmsSousIdMap[p.sousId]
        return { ...p, ...livePartnerFarmData }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
    })
    // Init pool data
    builder.addCase(fetchInitialPartnerFarmsData.fulfilled, (state, action) => {
      const poolsData = action.payload
      state.data = poolsData
    })
    builder.addCase(
      fetchPartnerFarmsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<PartnerFarmUserDataResponse[]>,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = fromPairs(userData.map((entry) => [entry.sousId, entry]))
        state.data = state.data.map((p) => ({
          ...p,
          userDataLoaded: true,
          userData: userDataSousIdMap[p.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchPartnerFarmsUserDataAsync.rejected, (state, action) => {
      console.error('[PartnerFarms Action] Error fetching partner farm user data', action.payload)
    })
    builder.addMatcher(
      isAnyOf(
        updateUserTokenAllowance.fulfilled,
        updateUserNftAllowance.fulfilled,
        updateUserHoldingTokens.fulfilled,
        updateUserStakedTokens.fulfilled,
        updateUserHoldingNfts.fulfilled,
        updateUserStakedNfts.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const {
  setPartnerFarmsPublicData,
  setPartnerFarmPublicData,
  setPartnerFarmUserData
} = PartnerFarmsSlice.actions

export default PartnerFarmsSlice.reducer
