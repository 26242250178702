import { ChainId, Token, WCRO } from '@pancakeswap/sdk'
import { BUSD_BSC, CAKE_MAINNET, CANDY_CRONOS, USDC_CRONOS, USDT_BSC, USDT_CRONOS } from './common'

export const cronosTokens = {
  wcro: WCRO[ChainId.CRONOS],
  // cro here points to the wcro contract. Wherever the currency CRO is required, conditional checks for the symbol 'CRO' can be used
  cro: new Token(
    ChainId.CRONOS,
    '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    18,
    'CRO',
    'CRO',
    'https://chain.crypto.com/',
  ),
  candy: CANDY_CRONOS,
  thundr: new Token(
    ChainId.CRONOS,
    "0xE71D72436F290Cd98CC29c9EF0E15C88Ce57B359",
    18,
    "THUNDR",
    "THUNDER",
    "https://islandthunder.io/"
  ),
  thundrcrolp: new Token(
    ChainId.CRONOS,
    "0x4F9B37C5AbdA884495dAc84d5be62A19c5365396",
    18,
    "THUNDR-CRO LP",
    "THUNDR-CRO LP"
  ),
  warzcrolp: new Token(
    ChainId.CRONOS,
    "0xDdCBee0F1AD911D6644A7361473Dfaef2048165d",
    18,
    "WARZ-CRO LP",
    "WARZ-CRO LP"
  ),
  usdc: USDC_CRONOS,
  usdt: USDT_CRONOS,
  weth: new Token(
    ChainId.CRONOS,
    '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    18,
    'WETH',
    'Wrapped Ether',
    'https://ethereum.org/en/',
  ),
  link: new Token(
    ChainId.CRONOS,
    '0xBc6f24649CCd67eC42342AccdCECCB2eFA27c9d9',
    18,
    'LINK',
    'ChainLink Token',
    'https://cronos.org/',
  ),
  wbtc: new Token(
    ChainId.CRONOS,
    '0x062E66477Faf219F25D27dCED647BF57C3107d52',
    8,
    'WBTC',
    'Wrapped BTC',
    'https://bitcoin.org/',
  ),
  vvs: new Token(
    ChainId.CRONOS,
    '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
    18,
    'VVS',
    'VVSToken',
    'https://vvs.finance/',
  ),
  mmf: new Token(
    ChainId.CRONOS,
    '0x97749c9B61F878a880DfE312d2594AE07AEd7656',
    18,
    'MMF',
    'Mad Meerkat Finance',
    'https://mm.finance/',
  ),
  tonic: new Token(
    ChainId.CRONOS,
    '0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2',
    18,
    'TONIC',
    'Tectonic Governance Token',
    'https://tectonic.finance/',
  ),
  scratch: new Token(
    ChainId.CRONOS,
    '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66',
    18,
    'SCRATCH',
    'Scratch',
    'https://www.boomersquad.io/',
  ),
  mtd: new Token(
    ChainId.CRONOS,
    '0x0224010BA2d567ffa014222eD960D1fa43B8C8E1',
    18,
    'MTD',
    'Minted Token',
    'https://minted.network/',
  ),
  croid: new Token(
    ChainId.CRONOS,
    '0xCbF0ADeA24fd5f32c6e7f0474f0d1b94Ace4E2e7',
    18,
    'CROID',
    'Cronos ID Token',
    'https://cronosid.xyz/',
  ),
  fer: new Token(
    ChainId.CRONOS,
    '0x39bC1e38c842C60775Ce37566D03B41A7A66C782',
    18,
    'FER',
    'FerroToken',
    'https://ferroprotocol.com/',
  ),
  mimas: new Token(
    ChainId.CRONOS,
    '0x10C9284E6094b71D3CE4E38B8bFfc668199da677',
    18,
    'MIMAS',
    'Mimas',
    'https://mimas.finance/',
  ),
  warz: new Token(
    ChainId.CRONOS,
    '0x1e942Cc71c28E8440201AbBFCDD0c657fB076BF7',
    18,
    'WARZ',
    'METAWARZ SHOOTER',
    'https://metawarz.io/',
  ),
  bcro: new Token(
    ChainId.CRONOS,
    '0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F',
    18,
    'BCRO',
    'Bonded CRO',
    'https://www.argofinance.money/stake',
  ),
  troll: new Token(
    ChainId.CRONOS,
    '0x679f6863a653251C8C215e77205A7058b5bF676a',
    9,
    'Troll',
    'Trollcoin',
    'http://trollcoin.clubcro.com/',
  ),
  ada: new Token(
    ChainId.CRONOS,
    '0x0e517979C2c1c1522ddB0c73905e0D39b3F990c0',
    6,
    'ADA',
    'ADA',
    'https://www.cardano.org/',
  ),
  doge: new Token(
    ChainId.CRONOS,
    '0x1a8E39ae59e5556B56b76fCBA98d22c9ae557396',
    8,
    'DOGE',
    'Dogecoin',
    'http://dogecoin.com/',
  ),
  atom: new Token(
    ChainId.CRONOS,
    '0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93',
    6,
    'ATOM',
    'ATOM',
    'https://cosmos.network/',
  ),
  shib: new Token(
    ChainId.CRONOS,
    '0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
    18,
    'SHIB',
    'SHIBA INU',
    'https://shibatoken.com/',
  ),
  lcro: new Token(
    ChainId.CRONOS,
    '0x9Fae23A2700FEeCd5b93e43fDBc03c76AA7C08A6',
    18,
    'LCRO',
    'Liquid CRO',
    'https://veno.finance/',
  ),
  fira: new Token(
    ChainId.CRONOS,
    '0x7ABa852082b6F763E13010CA33B5D9Ea4EeE2983',
    18,
    'FIRA',
    'Fira',
    'https://www.defira.com/',
  ),
  matic: new Token(
    ChainId.CRONOS,
    '0xf78a326ACd53651F8dF5D8b137295e434B7c8ba5',
    18,
    'MATIC',
    'Matic Token',
    'https://polygon.technology/',
  ),
  qnt: new Token(
    ChainId.CRONOS,
    '0x7d54F4E05f273a9317f723997612Ed64eF53C900',
    18,
    'QNT',
    'Quant',
    'https://quant.network/',
  ),
  vno: new Token(
    ChainId.CRONOS,
    '0xdb7d0A1eC37dE1dE924F8e8adac6Ed338D4404E9',
    18,
    'VNO',
    'VenoToken',
    'https://veno.finance/',
  ),
  argo: new Token(
    ChainId.CRONOS,
    '0x47A9D630dc5b28F75d3AF3be3AAa982512Cd89Aa',
    18,
    'ARGO',
    'Argo Protocol',
    'https://www.argofinance.money/',
  ),
  icy: new Token(
    ChainId.CRONOS,
    '0x8F857Af6Ea31447Bb502fE0E3F4e4340CDFCfc6C',
    18,
    'ICY',
    'IcyCRO',
    'https://www.icycro.org/',
  ),
  versa: new Token(
    ChainId.CRONOS,
    '0x00D7699b71290094CcB1a5884cD835bD65a78c17',
    18,
    'VERSA',
    'VersaGames',
    'https://versagames.io/',
  ),
  xtc: new Token(
    ChainId.CRONOS,
    '0xDD646291D2fff52c75F27CCDAdD0D4C2A24f37Dd',
    8,
    '$XTC',
    'Xitcoin',
    'https://xitcoin.org/',
  ),
  aut: new Token(
    ChainId.CRONOS,
    '0xD896aA25da8e3832d68d1C05fEE9C851d42F1dC1',
    8,
    'AUT',
    'AurumTrust',
  ),
  xtccrolp: new Token(
    ChainId.CRONOS,
    "0x4339Ad632438661Db56B4dd5ad82279de743676f",
    18,
    "$XTC-CRO LP",
    "$XTC-CRO LP"
  ),

  imp: new Token(
    ChainId.CRONOS,
    '0xfCe8595a467637AB901be1963962867C4cAD3C0e',
    18,
    'IMP',
    'IMPERIVM',
    'https://imperivm.io/',
  ),
  morphs: new Token(
    ChainId.CRONOS,
    "0x2e7F49fA8c9e2125795D2Cea37a6A958E30eeD32",
    9,
    "MORPHS",
    "MorphsCoin",
    "https://www.cromorphs.com/"
  ),
  morphscrolp: new Token(
    ChainId.CRONOS,
    "0xe5D47472Bd4cd5282dB9A03C6c12661459676D4B",
    18,
    "MORPHS-CRO LP",
    "MORPHS-CRO LP"
  ),
  blu: new Token(
    ChainId.CRONOS,
    '0x1542bA4CA0fb6D1B4476a933B292002fd1959A52',
    18,
    'BLU',
    'Bluespade',
    'https://www.bluespade.xyz/',
  ),
  star: new Token(
    ChainId.CRONOS,
    '0x8533369229eae1f7Be2aCD9d9E777E79AF081586',
    18,
    'STAR',
    'STAR',
    'https://www.cropepe.com/',
  ),
  vrse: new Token(
    ChainId.CRONOS,
    '0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1',
    18,
    'VRSE',
    'CronosVerse',
    'https://thecronosverse.com/',
  ),
  vrsecrolp: new Token(
    ChainId.CRONOS,
    "0x104Ae21aC93c67ee232F3841cabD7D26529B9BB7",
    18,
    "VRSE-CRO LP",
    "VRSE-CRO LP"
  ),
  bc: new Token(
    ChainId.CRONOS,
    '0x06725e8c3c54A1D3A7109E43a2d928a7De462eaa',
    18,
    'BC',
    'Burn Cro',
    'https://www.burncro.com/',
  ),
  bccrolp: new Token(
    ChainId.CRONOS,
    "0x58624FF4128440c9136Df9bcf8B9431e90340945",
    18,
    "BC-CRO LP",
    "BC-CRO LP"
  ),
  mad: new Token(
    ChainId.CRONOS,
    '0x212331e1435A8df230715dB4C02B2a3A0abF8c61',
    18,
    'MAD',
    'Mad Bucks',
    'https://app.ebisusbay.com/collection/cronos/mad-meerkat',
  ),

  madcrolp: new Token(
    ChainId.CRONOS,
    "0xB8F5eE44B45C3B5AaC0185dAD326EAE4B4A0e9BD",
    18,
    "MAD-CRO LP",
    "MAD-CRO LP"
  ),

  gainz: new Token(
    ChainId.CRONOS,
    '0xF7b1095D2af6C81c2d88f0ab44c7c2341BFfc411',
    18,
    '$GAINZ',
    'MONKEY MUSCLE',
    'https://app.ebisusbay.com/info/cronos/tokens/0xf7b1095d2af6c81c2d88f0ab44c7c2341bffc411',
  ),

  gainzcrolp: new Token(
    ChainId.CRONOS,
    "0x1c9548C165Fe816b438514Cc40899c91178E3651",
    18,
    "$GAINZ-CRO LP",
    "$GAINZ-CRO LP"
  ),
}
