import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { getPools } from 'config/constants/pools'
import {
  PoolsState,
  SerializedPool,
  SerializedVaultFees,
  SerializedCakeVault,
  SerializedLockedVaultUser,
  SerializedVaultUser,
  SerializedLockedCakeVault,
} from 'state/types'
import { Address, erc20ABI } from 'wagmi'
import { getPoolApr } from 'utils/apr'
import { publicClient } from 'utils/wagmi'
import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { CANDY, cronosTokens } from '@pancakeswap/tokens'
import { getBalanceNumber } from 'utils/formatBalance'
import { cronosRpcProvider, polygonRpcProvider } from 'utils/providers'
import { getPoolsPriceHelperLpFiles } from 'config/constants/priceHelperLps/index'
import fetchFarms from '../farms/fetchFarms'
import getFarmsPrices, { nativeStableLpMap } from '../farms/getFarmsPrices'

import {
  fetchPoolsBlockLimits,
  fetchPoolsFeeConf,
  fetchPoolsStakingLimits,
  fetchPoolsTotalStaking,
} from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeDatas,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees, fetchPublicFlexibleSideVaultData } from './fetchVaultPublic'
import { getLPTokenPrice, getTokenPricesFromFarm } from './helpers'
import { resetUserState } from '../global/actions'
import { fetchVaultUser, fetchFlexibleSideVaultUser } from './fetchVaultUser'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

const initialState: PoolsState = {
  data: [],
  userDataLoaded: false,
  cakeVault: initialPoolVaultState,
  cakeFlexibleSideVault: initialPoolVaultState,
}

export const fetchCakePoolPublicDataAsync = (chainId: number) => async (dispatch, getState) => {
  const poolsConfig = await getPools(chainId)
  const farmsData = getState().farms.data
  const prices = getTokenPricesFromFarm(farmsData)

  const cakePool = poolsConfig.filter((p) => p.sousId === 0)[0]

  const stakingTokenAddress = cakePool.stakingToken.address ? cakePool.stakingToken.address.toLowerCase() : null
  const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

  const earningTokenAddress = cakePool.earningToken.address ? cakePool.earningToken.address.toLowerCase() : null
  const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

  dispatch(
    setPoolPublicData({
      sousId: 0,
      data: {
        stakingTokenPrice,
        earningTokenPrice,
      },
    }),
  )
}

export const fetchCakePoolUserDataAsync = (account: string, chainId: number) => async (dispatch) => {
  const client = publicClient({ chainId })
  const [allowance, stakingTokenBalance] = await client.multicall({
    contracts: [
      {
        abi: erc20ABI,
        address: CANDY[chainId].address,
        functionName: 'allowance',
        args: [account as Address, getCakeVaultAddress(chainId)],
      },
      {
        abi: erc20ABI,
        address: CANDY[chainId].address,
        functionName: 'balanceOf',
        args: [account as Address],
      },
    ],
    allowFailure: false,
  })

  dispatch(
    setPoolUserData({
      sousId: 0,
      data: {
        allowance: new BigNumber(allowance.toString()).toJSON(),
        stakingTokenBalance: new BigNumber(stakingTokenBalance.toString()).toJSON(),
      },
    }),
  )
}

export const fetchPoolsPublicDataAsync =
  (currentBlockNumber: number, chainId: number) => async (dispatch, getState) => {
    try {
      const poolsConfig = await getPools(chainId)
      const [blockLimits, totalStakings, stakingLimits, feeConfs, /* profileRequirements, */ currentBlock] = await Promise.all([
        fetchPoolsBlockLimits(chainId),
        fetchPoolsTotalStaking(chainId),
        fetchPoolsStakingLimits(chainId),
        fetchPoolsFeeConf(chainId),
        // fetchPoolsProfileRequirement(chainId),
        currentBlockNumber
          ? Promise.resolve(currentBlockNumber)
          : chainId === 137
            ? polygonRpcProvider.getBlockNumber()
            : cronosRpcProvider.getBlockNumber(),
      ])

      const blockLimitsSousIdMap = fromPairs(blockLimits.map((entry) => [entry.sousId, entry]))
      const totalStakingsSousIdMap = fromPairs(totalStakings.map((entry) => [entry.sousId, entry]))
      const stakingLimitsSousIdMap = fromPairs(stakingLimits.map((entry) => [entry.sousId, entry]))
      const feeConfsSousIdMap = fromPairs(feeConfs.map((entry) => [entry.sousId, entry]))

      const priceHelperLpsConfig = getPoolsPriceHelperLpFiles(chainId)
      const activePriceHelperLpsConfig = priceHelperLpsConfig.filter((priceHelperLpConfig) => {
        return (
          poolsConfig
            .filter(
              (pool) => pool.earningToken.address.toLowerCase() === priceHelperLpConfig.token.address.toLowerCase(),
            )
            .filter((pool) => {
              const poolBlockLimit = blockLimitsSousIdMap[pool.sousId]
              if (poolBlockLimit) {
                return poolBlockLimit.endBlock > currentBlock
              }
              return false
            }).length > 0
        )
      })
      const poolsWithDifferentFarmToken =
        activePriceHelperLpsConfig.length > 0 ? await fetchFarms(priceHelperLpsConfig, chainId) : []

      const farmsData = getState().farms.data
      const stableLp = nativeStableLpMap[chainId]
      const bnbBusdFarm =
        activePriceHelperLpsConfig.length > 0
          ? farmsData.find((farm) => farm.token.symbol === stableLp.stable && farm.quoteToken.symbol === stableLp.wNative)
          : null
      const bnbBusdFarm1 = poolsWithDifferentFarmToken.length > 0
        ? poolsWithDifferentFarmToken.find((farm) => farm.token.symbol === stableLp.stable && farm.quoteToken.symbol === stableLp.wNative)
        : null
      const farmsWithPricesOfDifferentTokenPools = bnbBusdFarm
        ? await getFarmsPrices([bnbBusdFarm, ...poolsWithDifferentFarmToken], chainId)
        : bnbBusdFarm1
          ? await getFarmsPrices([bnbBusdFarm1, ...poolsWithDifferentFarmToken], chainId)
          : []

      // Hard-code for THUNDR-CRO & WARZ-CRO partner farm, we use pool module for the partner farms, so lp price should be used instead of token price
      const thundrCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.thundr.address)
      const thundrCroLPPrice = getLPTokenPrice(thundrCroHelperFarm)
      const xtcCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.xtc.address)
      const xtcCroLPPrice = getLPTokenPrice(xtcCroHelperFarm)
      const morphsCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.morphs.address)
      const morphsCroLPPrice = getLPTokenPrice(morphsCroHelperFarm)
      const warzCroHelperFarm = farmsWithPricesOfDifferentTokenPools.find((farm) =>
        farm.quoteToken.address === cronosTokens.wcro.address && farm.token.address === cronosTokens.warz.address)
      const warzCroLPPrice = getLPTokenPrice(warzCroHelperFarm)

      const prices = {
        ...getTokenPricesFromFarm([...farmsData, ...farmsWithPricesOfDifferentTokenPools]),
        [cronosTokens.thundrcrolp.address.toLocaleLowerCase()]: thundrCroLPPrice?.toNumber(),
        [cronosTokens.xtccrolp.address.toLocaleLowerCase()]: xtcCroLPPrice?.toNumber(),
        [cronosTokens.warzcrolp.address.toLocaleLowerCase()]: warzCroLPPrice?.toNumber(),
        [cronosTokens.morphscrolp.address.toLocaleLowerCase()]: morphsCroLPPrice?.toNumber(),
      }

      const liveData = poolsConfig.map((pool) => {
        const blockLimit = blockLimitsSousIdMap[pool.sousId]
        const totalStaking = totalStakingsSousIdMap[pool.sousId]
        const stakingLimit = stakingLimitsSousIdMap[pool.sousId]
        const feeConf = feeConfsSousIdMap[pool.sousId]

        const isPoolEndBlockExceeded =
          currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
        const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

        const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
        const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

        const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
        const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

        const apr = !isPoolFinished
          ? getPoolApr(
            stakingTokenPrice,
            earningTokenPrice,
            getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
            parseFloat(pool.tokenPerBlock),
          )
          : 0

        // const profileRequirement = profileRequirements[pool.sousId] ? profileRequirements[pool.sousId] : undefined

        return {
          ...blockLimit,
          ...totalStaking,
          ...stakingLimit,
          ...feeConf,
          // profileRequirement,
          stakingTokenPrice,
          earningTokenPrice,
          apr,
          isFinished: isPoolFinished,
        }
      })

      dispatch(setPoolsPublicData(liveData))
    } catch (error) {
      console.error('[Pools Action] error when getting public data', error)
    }
  }

// export const fetchPoolsStakingLimitsAsync = (chainId: number) => async (dispatch, getState) => {
//   const poolsConfig = await getPools(chainId)
//   const poolsWithStakingLimit = getState()
//     .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
//     .map((pool) => pool.sousId)

//   try {
//     const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit, chainId)

//     const stakingLimitData = poolsConfig.map((pool) => {
//       if (poolsWithStakingLimit.includes(pool.sousId)) {
//         return { sousId: pool.sousId }
//       }
//       const { stakingLimit, numberBlocksForUserLimit } = stakingLimits[pool.sousId] || {
//         stakingLimit: BIG_ZERO,
//         numberBlocksForUserLimit: 0,
//       }
//       return {
//         sousId: pool.sousId,
//         stakingLimit: stakingLimit.toJSON(),
//         numberBlocksForUserLimit,
//       }
//     })

//     dispatch(setPoolsPublicData(stakingLimitData))
//   } catch (error) {
//     console.error('[Pools Action] error when getting staking limits', error)
//   }
// }

export const fetchInitialPoolsData = createAsyncThunk<SerializedPool[], { chainId: number }>(
  'pool/fetchInitialPoolsData',
  async ({ chainId }) => {
    const poolDataList = await getPools(chainId)
    return poolDataList
  }
)

export const fetchPoolsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; lastDepositAt: number; pendingReward: any }[],
  { account: string; chainId: number }
>('pool/fetchPoolsUserData', async ({ account, chainId }, { rejectWithValue }) => {
  try {
    const poolsConfig = await getPools(chainId)
    const [allowances, stakingTokenBalances, stakedDatas, pendingRewards] = await Promise.all([
      fetchPoolsAllowance(account, chainId),
      fetchUserBalances(account, chainId),
      fetchUserStakeDatas(account, chainId),
      fetchUserPendingRewards(account, chainId)
    ])

    const userData = poolsConfig.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      ...stakedDatas[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const updateUserAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('pool/updateUserAllowance', async ({ sousId, account, chainId }) => {
  const allowances = await fetchPoolsAllowance(account, chainId)
  return { sousId, field: 'allowance', value: allowances[sousId] }
})

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('pool/updateUserBalance', async ({ sousId, account, chainId }) => {
  const tokenBalances = await fetchUserBalances(account, chainId)
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedData = createAsyncThunk<
  { sousId: number; value: any },
  { sousId: number; account: string; chainId: number }
>('pool/updateUserStakedData', async ({ sousId, account, chainId }) => {
  const stakedDatas = await fetchUserStakeDatas(account, chainId)
  return { sousId, value: stakedDatas[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; chainId: number }
>('pool/updateUserPendingReward', async ({ sousId, account, chainId }) => {
  const pendingRewards = await fetchUserPendingRewards(account, chainId)
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})

export const fetchCakeVaultPublicData = createAsyncThunk<SerializedLockedCakeVault, { chainId: number }>(
  'cakeVault/fetchPublicData',
  async ({ chainId }) => {
    const publicVaultInfo = await fetchPublicVaultData(chainId)
    return publicVaultInfo
  },
)

export const fetchCakeFlexibleSideVaultPublicData = createAsyncThunk<SerializedCakeVault, { chainId: number }>(
  'cakeFlexibleSideVault/fetchPublicData',
  async ({ chainId }) => {
    const publicVaultInfo = await fetchPublicFlexibleSideVaultData(chainId)
    return publicVaultInfo
  },
)

export const fetchCakeVaultFees = createAsyncThunk<SerializedVaultFees, { chainId: number }>('cakeVault/fetchFees', async ({ chainId }) => {
  const vaultFees = await fetchVaultFees(getCakeVaultAddress(chainId), chainId)
  return vaultFees
})

export const fetchCakeFlexibleSideVaultFees = createAsyncThunk<SerializedVaultFees, { chainId: number }>(
  'cakeFlexibleSideVault/fetchFees',
  async ({ chainId }) => {
    const vaultFees = await fetchVaultFees(getCakeFlexibleSideVaultAddress(chainId), chainId)
    return vaultFees
  },
)

export const fetchCakeVaultUserData = createAsyncThunk<SerializedLockedVaultUser, { account: string; chainId: number }>(
  'cakeVault/fetchUser',
  async ({ account, chainId }) => {
    const userData = await fetchVaultUser(account, chainId)
    return userData
  },
)

export const fetchCakeFlexibleSideVaultUserData = createAsyncThunk<SerializedVaultUser, { account: string; chainId: number }>(
  'cakeFlexibleSideVault/fetchUser',
  async ({ account, chainId }) => {
    const userData = await fetchFlexibleSideVaultUser(account, chainId)
    return userData
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((pool) => {
        if (pool.sousId === sousId) {
          return { ...pool, userDataLoaded: true, userData: action.payload.data }
        }
        return pool
      })
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = fromPairs(livePoolsData.map((entry) => [entry.sousId, entry]))
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsSousIdMap[pool.sousId]
        return { ...pool, ...livePoolData }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
      state.cakeVault = { ...state.cakeVault, userData: initialPoolVaultState.userData }
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData: initialPoolVaultState.userData }
    })
    // Init pool data
    builder.addCase(fetchInitialPoolsData.fulfilled, (state, action) => {
      const poolsData = action.payload
      state.data = poolsData
    })
    builder.addCase(
      fetchPoolsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; lastDepositAt: number; pendingReward: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = fromPairs(userData.map((entry) => [entry.sousId, entry]))
        state.data = state.data.map((pool) => ({
          ...pool,
          userDataLoaded: true,
          userData: userDataSousIdMap[pool.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchPoolsUserDataAsync.rejected, (state, action) => {
      console.error('[Pools Action] Error fetching pool user data', action.payload)
    })
    // Vault public data that updates frequently
    builder.addCase(fetchCakeVaultPublicData.fulfilled, (state, action: PayloadAction<SerializedLockedCakeVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultPublicData.fulfilled,
      (state, action: PayloadAction<SerializedCakeVault>) => {
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, ...action.payload }
      },
    )
    // Vault fees
    builder.addCase(fetchCakeVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    builder.addCase(fetchCakeFlexibleSideVaultFees.fulfilled, (state, action: PayloadAction<SerializedVaultFees>) => {
      const fees = action.payload
      state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCakeVaultUserData.fulfilled, (state, action: PayloadAction<SerializedLockedVaultUser>) => {
      const userData = action.payload
      state.cakeVault = { ...state.cakeVault, userData }
    })
    builder.addCase(
      fetchCakeFlexibleSideVaultUserData.fulfilled,
      (state, action: PayloadAction<SerializedVaultUser>) => {
        const userData = action.payload
        state.cakeFlexibleSideVault = { ...state.cakeFlexibleSideVault, userData }
      },
    )
    builder.addCase(
      updateUserStakedData.fulfilled, (state, action: PayloadAction<{ sousId: number; value: any }>) => {
        const { sousId, value } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, ...value } }
        }
      }
    )
    builder.addMatcher(
      isAnyOf(
        updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setPoolsPublicData, setPoolPublicData, setPoolUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
